body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu .ant-menu {
  line-height: 62px;
  height: 64px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content6-wrapper {
  min-height: 720px;
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  align-items: center;
}
.content6-wrapper .content6-text {
  min-height: 424px;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 48px;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
img {
  -webkit-user-drag: none;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner0_2 .banner0-text-wrapper > .kf6x5pxrtlc-editor_css {
  font-size: 16px;
}
.github-btn {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
